<template>
  <div class="device">
    <p class="device-title">设备档案</p>

    <el-row class="device-summary" v-if="false">
      <el-col :span="8" class="summary-info">
        <div class="summary-info-item">
          <p>设备总数<span>(台)</span></p>
          <p class="summary-info-title">{{analyze.total | I1000}}</p>
        </div>
        <div class="summary-info-item">
          <p>在线设备<span>(台)</span></p>
          <p class="summary-info-title">{{analyze.online | I1000}}</p>
        </div>
        <div class="summary-info-item">
          <p>
            <el-tooltip placement="top">
              <template slot="title">
                <span>设备折旧值=</span>
              </template>
              <el-icon type="question-circle"/>
            </el-tooltip>
            设备制作统计
          </p>
          <p class="summary-info-title">{{analyze.avgMake}} <span class="summary-info-remark">(杯/天)</span></p>
        </div>
        <div class="summary-info-item">
          <p>累计制作杯量</p>
          <p class="summary-info-title">
            {{analyze.sumCups | I1000}} <span class="summary-info-remark">杯</span>
          </p>
        </div>
      </el-col>
      <el-col :span="16">
        <div id="org_device_chart" class="chart"></div>
      </el-col>
    </el-row>


    <device-list :device-list="handleDeviceList" :device-union="loadDevice">

      <el-table-column label="更多操作" fixed="right" width="180px">
        <template slot-scope="scope">
          <el-button v-if="$btnCheck('order_query_info')"
                     type="text" size="small" @click="handleDeviceDetail(scope.row, 'order')">设备订单
          </el-button>
          <el-button v-if="$btnCheck('equipment_device_info')"
            type="text" size="small" @click="handleDeviceDetail(scope.row)">信息管理</el-button>
        </template>

      </el-table-column>
    </device-list>
  </div>


</template>

<script>
  import * as echarts from 'echarts';

  import DeviceList from '@/components/DeviceList'

  export default {
    name: 'equipment-device',
    components: {
      DeviceList
    },
    mounted: function () {
      this.$api('equipment.device.analyze').then(data => {
        this.analyze = data || this.analyze
      })
    },
    data: function () {
      return {
        option: {
          title: {
            text: '本周每天在线时长',
            textStyle: {
              align: 'center',
              color: '#000',
              fontWeight: 'bold',
              fontSize: 14,
            },
            left: 'center',
          },
          legend: {
            type: 'plain',
            right: 60,
          },
          tooltip: {
            show: true,
            trigger: 'item',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            top: 40,
            left: '10%',
            right: 30,
            bottom: 40,
          },
          xAxis: {
            name: '',
            boundaryGap: false,
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              lineStyle: {
                color: '#E2E2E2'
              }
            },
            axisLabel: {
              color: '#333'
            }
          },
          yAxis: {
            type: 'value',
            interval: 2,
            axisLabel: {
              formatter: '{value}'
            },
            axisPointer: {
              snap: true
            }
          },
          series: []
        },
        analyze: {
          avgMake: 0,
          online: 0,
          sumCups: 0,
          total: 0
        },
        devices: [],
        loading: false,
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      handleDeviceDetail(device, step = '') {
        this.$router.push({name: 'equipment.device.detail', params: {uuid: device.uuid, step: step}})
      },
      handleDeviceList(value, fn) {
        this.$api('equipment.device.list', value).then(fn)
      },
      loadDevice(val, fn) {
        this.$api('equipment.device.union', val).then(fn)
      },
      _initCharts() {
        let temp = [
          {
            name: '在线时长',
            type: 'line',
            smooth: true,
            data: [6.5, 8, 5, 0, 0]
          },
          {
            name: '制作杯量',
            type: 'line',
            smooth: true,
            data: [1, -2, 2, 5, 3, 2, 0],
            markPoint: {
              data: [
                {name: '周最低', value: -2, xAxis: 1, yAxis: -1.5}
              ]
            },
            markLine: {
              data: [
                {type: 'average', name: '平均值'},
                [{
                  symbol: 'none',
                  x: '90%',
                  yAxis: 'max'
                }, {
                  symbol: 'circle',
                  label: {
                    position: 'start',
                    formatter: '最大值'
                  },
                  type: 'max',
                  name: '最高点'
                }]
              ]
            }
          }
        ]

        this.option.series = temp
        let chart = echarts.init(document.getElementById('org_device_chart'))
        chart.setOption(this.option)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .device {
    @include container();

    &-title {
      @include page-title();
    }

    &-summary {
      height: 240px;
      border-bottom: 1px solid $content-border-color;

      .summary-info {
        padding-top: $large-space;
        padding-left: $middle-space;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        &-item {
          margin-bottom: $middle-space;
          width: 50%;
        }

        &-title {
          @include font-large-s();
          color: $color-black;
          padding: $middle-space $small-space;
        }

        &-remark {
          @include font-little-h();
          color: $remark-text-color;
        }
      }
    }

    .chart {
      height: 240px;
    }

  }
</style>
